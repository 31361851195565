<template>
  <div>
    <MainHeader />
    <div class="container">
      <div class="simplifySection">
        <img class="my-2" src="/assets/images/svgs/magic-star.svg" alt="star" />
        <h2>Simplifying Access to Affordable and Reliable Energy</h2>
        <p class="f-18">
          At Imperium, we are creating an ecosystem where the consumers
          (individuals and companies) and the service providers can interact
          seamlessly to support equal access to affordable energy.
        </p>
      </div>
    </div>
    <div class="sectionPad pt-0">
      <div class="container">
        <div class="row">
          <div class="col-md-5">
            <img
              src="/assets/images/svgs/evaluation.svg"
              class="img-fluid evaluationImg"
              alt="evaluation"
            />
          </div>
          <div class="col-md-7">
            <div class="evaluation">
              <h2>
                <span class="text-primary">Evaluation:</span> Energy Audits For
                Everyone
              </h2>
              <div class="mt-5">
                <div class="row">
                  <div class="col-md-6">
                    <div class="evaluateMedia media">
                      <a href="#" class="d-flex"
                        ><img src="/assets/images/svgs/archive-tick.svg" alt=""
                      /></a>
                      <div class="media-body pl-2">
                        <p>
                          Calculate the energy consumption for your home,
                          business or community.
                        </p>
                      </div>
                    </div>
                    <div class="evaluateMedia media">
                      <a href="#" class="d-flex"
                        ><img src="/assets/images/svgs/archive-tick.svg" alt=""
                      /></a>
                      <div class="media-body pl-2">
                        <p>Request for onsite energy consumption evaluation.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="evaluateMedia media">
                      <a href="#" class="d-flex"
                        ><img src="/assets/images/svgs/archive-tick.svg" alt=""
                      /></a>
                      <div class="media-body pl-2">
                        <p>
                          Start with our solar calculator to find out what you
                          could save and power
                        </p>
                      </div>
                    </div>
                    <div class="evaluateMedia media">
                      <router-link
                        to="/energy-calculator"
                        class="btn btn-imp-secondary bg-primary text-white btn-icon mt-2 py-3 px-5"
                      >
                        <span class="nav-link-inner--text">Make a request</span>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sectionPad">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="evaluation">
              <h2>
                <span class="text-primary">Solutions:</span> Flexible Purchase
                Plan that Works For You
              </h2>
              <div class="mt-5">
                <div class="evaluateMedia media">
                  <a href="#" class="d-flex"
                    ><img src="/assets/images/svgs/archive-tick.svg" alt=""
                  /></a>
                  <div class="media-body pl-2">
                    <p>
                      Our Lease to Own model offers you the option of paying in
                      instalments over a fixed period and owning the products
                      after paying off your final installment.
                    </p>
                  </div>
                </div>
                <div class="evaluateMedia media">
                  <a href="#" class="d-flex"
                    ><img src="/assets/images/svgs/archive-tick.svg" alt=""
                  /></a>
                  <div class="media-body pl-2">
                    <p>
                      With Outright Purchase, you can instantly buy and own
                      renewable energy products.
                    </p>
                  </div>
                </div>
                <div class="evaluateMedia media">
                  <a href="#" class="d-flex"
                    ><img src="/assets/images/svgs/archive-tick.svg" alt=""
                  /></a>
                  <div class="media-body pl-2">
                    <p>
                      Power as a Service is an energy subscription model that
                      lets you enjoy electricity without purchasing
                      infrastructure similar to PHCN where you pay for the power
                      consumed.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="row">
              <div class="col-md-6">
                <div class="layerCard bg1">
                  <img
                    class="py-3"
                    src="/assets/images/svgs/layer.svg"
                    alt="stack"
                  />
                  <h4>Solar Home Systems</h4>
                  <p>
                    Enjoy uninterrupted power supply for households and
                    residential areas.
                  </p>
                </div>
                <div class="layerCard bg2">
                  <img
                    class="py-3"
                    src="/assets/images/svgs/layer.svg"
                    alt="stack"
                  />
                  <h4>Mini Grid</h4>
                  <p>
                    Empower clusters and communities with utility scale systems
                    and distributed energy.
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="layerCard bg3 mt-4">
                  <img
                    class="py-3"
                    src="/assets/images/svgs/layer.svg"
                    alt="stack"
                  />
                  <h4>Solar Appliances</h4>
                  <p>
                    Enjoy energy with our innovation solar appliances designed
                    to meet basic energy needs.
                  </p>
                </div>
                <div class="layerCard bg4">
                  <img
                    class="py-3"
                    src="/assets/images/svgs/layer.svg"
                    alt="stack"
                  />
                  <h4>Industrial Solutions</h4>
                  <p>
                    Energy solutions for business corporate organizations and
                    for other energy intensive activities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sectionPad pt-5">
      <div class="container">
        <div class="partnership text-center">
          <img
            class="mb-4"
            src="/assets/images/svgs/magic-star.svg"
            alt="star"
          />
          <h3>Partnership: Creating a Sustainable Future</h3>
          <p>Our platform is open to vendors in the renewable energy sector.</p>
        </div>
        <div class="">
          <div class="row" style="justify-content: space-around">
            <div class="col-md-3">
              <div class="partnerdiv text-center-sm">
                <img
                  class="my-3"
                  src="/assets/images/svgs/like.svg"
                  alt="like"
                />
                <h4>38 Partnerships</h4>
                <p class="f-18">
                  Our platform is open to vendors in the renewable energy
                  sector.
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="partnerdiv text-center-sm">
                <img
                  class="my-3"
                  src="/assets/images/svgs/home.svg"
                  alt="home"
                />
                <h4>1000 Homes</h4>
                <p class="f-18">
                  We have a record of over 1000 installations across different
                  geographical locations.
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="partnerdiv text-center-sm">
                <img
                  class="my-3"
                  src="/assets/images/svgs/discovery.svg"
                  alt="discovery"
                />
                <h4>36 States</h4>
                <p class="f-18">
                  We ensure that demands can be made across the nation with fast
                  and reliable delivery systems
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sectionPad">
      <div class="container">
        <div class="partnership bordered text-center">
          <div class="row justify-content-center">
            <div class="col-md-10">
              <h3 class="circleBefore mb-5">Featured Ventures</h3>
              <p>
                We are creating an ecosystem of consumers — individuals,
                corporates or clusters — funding agencies, energy solution
                providers, technical support and regulators.
              </p>
              <div class="venturesImg">
                <img src="/assets/images/logos/image 7.png" alt="ventures" />
                <img src="/assets/images/logos/image 8.png" alt="ventures" />
                <img src="/assets/images/logos/image 9.png" alt="ventures" />
                <img src="/assets/images/logos/image 10.png" alt="ventures" />
                <img src="/assets/images/logos/image 11.png" alt="ventures" />
                <img src="/assets/images/logos/image 12.png" alt="ventures" />
              </div>
              <div class="becomeVendor">
                <h3>Become a vendor</h3>
                <a
                  href="https://vendor.imperiumng.com/#/signup"
                  target="_blank"
                >
                  <div class="signUpView">
                    <span> Sign up </span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="partnership text-center mt-5">
          <div class="row justify-content-center">
            <div class="col-md-10">
              <h3 class="circleBefore mb-5 mt-5">Global Partnership</h3>
              <p class="mb-5">
                A network of local and international organisations dedicated to
                reliable energy sources.
              </p>
              <div class="row mt-6">
                <div class="col-md-3 col-6">
                  <div class="partnerLogo">
                    <img
                      src="/assets/images/logos/image 15.png"
                      alt="partners"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-6">
                  <div class="partnerLogo">
                    <img
                      src="/assets/images/logos/image 16.png"
                      alt="partners"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-6">
                  <div class="partnerLogo">
                    <img
                      src="/assets/images/logos/image 17.png"
                      alt="partners"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-6">
                  <div class="partnerLogo">
                    <img
                      src="/assets/images/logos/image 18.png"
                      alt="partners"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainHeader from "@/components/Home/MainHeader.vue";

export default {
  name: "Home",
  components: {
    MainHeader,
  },
};
</script>

<style lang="scss">
.simplifySection {
  margin: 60px 0px 100px;
  padding: 53px 200px;
  background-color: #fff7d6;
  background-image: url("/assets/images/svgs/simplybg.svg");
  background-repeat: no-repeat;
  border-radius: 30px;
  text-align: center;
  h2 {
    font-weight: bold;
    font-size: 30px;
    line-height: 45px;
    color: #219653;
  }
  p {
    font-size: 16px;
    color: #202020;
    margin: 0px;
  }
}
.evaluation {
  h2 {
    font-weight: bold;
    font-size: 40px;
    color: black;
  }
  .evaluateMedia {
    margin-bottom: 20px;
    p {
      font-weight: 300;
      font-size: 18px;
      color: #202020;
    }
  }
}
.layerCard {
  border-radius: 25px;
  padding: 22px;
  margin-bottom: 20px;
  &.bg1 {
    background: #f9f8e2;
  }
  &.bg2 {
    background: #e0ecce;
  }
  &.bg3 {
    background: #fff7d6;
  }
  &.bg4 {
    background: #e7effe;
  }
  h4 {
    font-weight: bold;
    font-size: 18px;
    color: #219653;
  }
  p {
    font-weight: normal;
    font-size: 14px;
    color: #202020;
    margin: 0;
  }
}
.partnership {
  padding-bottom: 50px;
  &.bordered {
    border-bottom: 2px dashed #5a9559;
  }
  h3 {
    font-size: 40px;
    color: #219653;
    font-weight: bold;
  }
  p {
    font-weight: 300;
    font-size: 22px;
    color: #202020;
  }
}
.partnerdiv {
  h4 {
    font-weight: bold;
    font-size: 24px;
    color: #202020;
  }
  p {
    font-weight: 300;
    font-size: 16px;
    color: #202020;
  }
}
.venturesImg {
  margin-top: 70px;
  img {
    height: 64px;
    margin: 0px 31px;
  }
}
.circleBefore {
  display: inline-block;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    height: 99px;
    z-index: -9;
    width: 99px;
    background: #e0ecce;
    border-radius: 90px;
    left: -32px;
    bottom: -20px;
  }
}
.becomeVendor {
  margin-top: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  h3 {
    display: inline-block;
    font-weight: bold;
    font-size: 48px;
    color: #202020;
  }
  .signUpView {
    display: inline-block;
    margin-left: 20px;
    width: 134px;
    height: 133px;
    background-image: url("/assets/images/svgs/polygon.svg");
    background-position: center;
    padding-top: 52px;
    cursor: pointer;
    span {
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      color: #219653;
    }
  }
}
.partnerLogo {
  height: 237.55px;
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(37, 94, 19, 0.5);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 140px;
  }
}
@media only screen and (max-width: 768px) {
  .simplifySection {
    padding: 25px 31px;
  }
  .evaluationImg {
    width: 76%;
  }
  .evaluation h2 {
    font-size: 31px;
    line-height: 1.3;
    margin-top: 23px;
  }
  .partnership {
    padding-bottom: 0px;
  }
  .venturesImg img {
    height: 64px;
    margin: 17px 31px;
  }
  .becomeVendor h3 {
    font-size: 25px;
    line-height: 1;
  }
  .partnerLogo {
    margin-bottom: 30px;
  }
}
</style>

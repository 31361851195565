<template>
  <div class="section section-pd bg-pr-trans">
    <div class="page-header">
      <div
        class="container shape-container d-flex align-items-center text-center-sm"
      >
        <div class="col px-0">
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-5">
              <h1 class="text-dark display-1 f-bold text-head mb-3">
                The Future is<br />
                <span class="text-typewriter">Sustainable</span>
              </h1>
              <p class="f-18 text-dark mb-0">
                We are building smarter, safer and <br />reliable energy
                solutions.
              </p>
              <div class="btn-wrapper mt-3">
                <!-- <a
                  href="https://shop.imperiumng.com/energy-calculator"
                  rel="noopener noreferrer"
                  class="btn btn-imp-secondary
                    bg-primary
                    text-white
                    btn-icon
                    mt-2
                    py-3
                    px-5"
                >
                  <span class="nav-link-inner--text">See how</span>
                </a> -->
                <router-link
                  to="/newsales"
                  class="btn btn-imp-secondary bg-primary text-white btn-icon mt-2 py-3 px-5"
                >
                  See how
                </router-link>
              </div>
            </div>
            <div class="col-md-7">
              <div class="text-center">
                <!-- <HeaderSvg /> -->
                <img
                  class="headerimg"
                  src="/assets/images/svgs/bannerimgcss.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderSvg from "@/components/Home/HeaderSvg.vue";

export default {
  components: {
    HeaderSvg,
  },
  mounted() {
    // array with texts to type in typewriter
    var dataText = ["Renewable", "Sustainable", "Imperium", ""];

    // type one text in the typwriter
    // keeps calling itself until the text is finished
    let typeWriter = (text, i, fnCallback) => {
      // chekc if text isn't finished yet
      if (i < text.length) {
        // add next character to h1
        document.querySelector(".text-typewriter").innerHTML =
          text.substring(0, i + 1) + '<span aria-hidden="true"></span>';

        // wait for a while and call this function again for next character
        setTimeout(function () {
          typeWriter(text, i + 1, fnCallback);
        }, 200);
      }
      // text finished, call callback if there is a callback function
      else if (typeof fnCallback == "function") {
        // call callback after timeout
        setTimeout(fnCallback, 700);
      }
    };
    // start a typewriter animation for a text in the dataText array
    function StartTextAnimation(i) {
      if (typeof dataText[i] == "undefined") {
        setTimeout(function () {
          StartTextAnimation(0);
        }, 20000);
      }
      // check if dataText[i] exists
      if (i < dataText[i].length) {
        if (i + 1 == dataText.length) {
          i = 0;
        } else {
        }
        // text exists! start typewriter animation
        typeWriter(dataText[i], 0, function () {
          // after callback (and whole text has been animated), start next text
          StartTextAnimation(i + 1);
        });
      } else {
        StartTextAnimation(0);
      }
    }
    // start the text animation
    StartTextAnimation(0);
  },
};
</script>
<style lang="scss">
.bg-prp {
  background: #f5fcf2;
}
.headerimg {
  height: 410px;
}
a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}
@media only screen and (max-width: 768px) {
  .headerimg {
    width: 90%;
  }
}
.text-typewriter {
  border-right: 0.05em solid;
  animation: caret 1s steps(1) infinite;
}

@keyframes caret {
  50% {
    border-color: transparent;
  }
}
</style>
